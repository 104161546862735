@import "../../assets/css/scss-variables.scss";

.navbar {
  z-index: 1;
  position: fixed;
  width: 100%;
  height: 65px;
  background-color: #8c8ce6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 10px 20px;
}

.navbar-icon {
  display: flex;
  align-items: center;
}

.navbar-icon h2 {
  font-size: 1.4em;
  padding: 10px 24px;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-end button {
  margin-right: 20px;
}

.navbar-end svg {
  margin-right: 15px;
}

.navbar-end .user-image {
  object-fit: cover;
  height: 45px;
  width: 45px;
  border-radius: 100px;
  border: 1px solid rgba(200, 200, 200, 0.3);
}

.navbar .user-image:hover {
  border: 1px solid rgba(200, 200, 200, 0.8);
}

@media only screen and (min-width: $media-sm) {
}

@media only screen and (min-width: $media-md) {
  .navbar {
    padding: 10px 48px;
  }
  .navbar-icon h2 {
    font-size: 1.6em;
  }
}
