@import "~react-image-gallery/styles/scss/image-gallery.scss";

.slideshow {
  height: 100vh;
  width: 100vw;
}
.image-gallery {
  padding: 20px;
  width: 100%;
  height: 100%;
}

.image-gallery-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-gallery-thumbnails-wrapper {
  align-self: flex-start;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 20px) !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
  height: 50px;
  object-fit: cover;
}

.image-gallery-thumbnails {
  height: 100% !important;
}

.thumbnails-swipe-vertical {
  height: 100% !important;
}
