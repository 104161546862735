@import "../../assets/css/scss-variables.scss";

.modal-open {
  overflow: hidden;
}
.opwrapper {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  padding: 20px 0px;
}

// .wrapper {
//   position: fixed;
//   z-index: 2;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   overflow-x: scroll;
// }

.opwrapper-content {
  max-width: 90%;
}

@media only screen and (min-width: $media-sm) {
  .opwrapper-content {
    min-width: 500px;
  }
}
