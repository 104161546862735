.tooltip {
  background-color: #fff;
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 1px 2px 8px #efefef;
  border-radius: 15px;
  max-width: 300px;
}

.collapsed {
  position: fixed;
  top: 3%;
  right: 3%;
  opacity: 0.3;
  z-index: 10;
}

.collapsed .fa-cog {
  filter: drop-shadow(1px 2px 2px #efefef);
}
.collapsed:hover {
  opacity: 1;
}

.select-layout {
  display: flex;
  flex-direction: row;
}

.select-layout .layout-item {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin: 0 5px;
  color: #242424;
  justify-content: center;
  align-items: center;
}

.select-layout .active {
  border: 1px solid var(--color-primary);
}

.select-layout .layout-item img {
  height: auto;
  width: 25px;
}

.select-delay {
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-delay {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-color {
  margin-top: 15px;
  width: 100%;
}

.tooltip p {
  color: #242424;
  text-align: center;
}
.button-container {
  display: flex;
}

.button-container button {
  margin: 0px 10px;
}

.hideScroll {
  overflow: auto;
}
.hideScroll::-webkit-scrollbar {
  background-color: #242424;
}
