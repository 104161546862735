.popup-wrapper {
  padding: 24px;
  background-color: white;
}

.popup-head {
  margin-bottom: 10px;
}

.popup-buttons {
  margin-top: 10px;
  display: flex;
}

.popup-buttons button {
  margin-right: 10px;
}
