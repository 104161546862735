@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 40px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 32px;
  margin: 15px;
}
h4 {
  font-size: 24px;
}

p {
  font-size: 16px;
}

#root {
  height: 100%;
}

/* Toast alerts */
.alert-container {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.alert {
  z-index: 1000;
  background-color: #fff;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
}

.alert-error .alert-body .alert-icon {
  color: #e30021;
}
.alert-error .alert-body .alert-loadbar {
  background-color: #e30021;
}
.alert-info .alert-body .alert-icon {
  color: #6a69e2;
}
.alert-info .alert-body .alert-loadbar {
  background-color: #6a69e2;
}
.alert-success .alert-body .alert-icon {
  color: #0da6a0;
}
.alert-success .alert-body .alert-loadbar {
  background-color: #0da6a0;
}

.alert .alert-body {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  min-width: 25vw;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.alert-body .alert-icon {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20%;
}

.alert-body .alert-content {
  padding: 18px 0 18px 0;
  width: 60%;
}

.alert-body .alert-action {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20%;
}

.input-error {
  color: red;
  font-size: 12px;
  top: -8px;
  position: relative;
}

.form-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.ReactCrop-Avatar div {
  display: flex;
  justify-content: center;
}

.ReactCrop-Avatar .ReactCrop__image,
.ReactCrop-Avatar .ReactCrop--image-copy {
  max-height: 50vh;
  max-width: auto;
}

.wrapper {
  display: block;
  min-height: 100vh;
  position: relative;
  width: 100%;
  height: 100%;
}

.content-without-nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content-without-nav .container {
  width: 100%;
}

.content {
  height: calc(100% - 100px);
  width: 100%;
  padding-top: 65px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctn {
  width: 100%;
}

/* Login Page */

.login-card {
  padding: 64px 24px;
}

.social-logo-box {
  background-color: #fafafa;
  border-radius: 128px;
  padding: 10px;
  height: 52px;
  width: 52px;
  margin-right: 10px;
  box-shadow: 1px 2px 3px rgb(0, 0, 0, 0.1);
  /* border-width: 1px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-logo-box:hover {
  background-color: #f5f5f5;
  box-shadow: 1px 2px 3px rgb(0, 0, 0, 0.25);
}

.social-logo-box img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (min-width: 600px) {
  .login-card {
    padding: 64px;
  }
}

.div-register,
.div-login {
  display: none;
  background-color: blueviolet;
}

@media screen and (min-width: 1190px) {
  .content-without-nav .container {
    display: flex;
    align-items: center;
  }
  .div-register {
    background-image: url("assets/images/login.jpg");
    background-size: cover;
    display: block;
    height: 100vh;
    width: 40%;
  }

  .div-login {
    background-image: url("assets/images/signup.jpg");
    background-size: cover;
    display: block;
    height: 100vh;
    width: 40%;
  }

  .login-card {
    width: calc(60% - 128px);
  }
}

/* Dashboard Page */

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.no-event {
  max-width: 600px;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-event h3 {
  text-align: center;
}

.events-wrapper {
  padding: 24px 0;
  margin: 0 auto;
  max-width: 1200px;
}

.grid-row {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  padding: 24px 48px;
}

.events-wrapper .grid-row .grid-item {
  -ms-flex: auto;
  position: relative;
  box-sizing: border-box;
  flex-basis: 100%;
  margin-bottom: 25px;
}

@media screen and (min-width: 800px) {
  .events-wrapper .grid-row .grid-item {
    flex-basis: calc(50% - 25px);
    margin: 0px 25px 10px 0;
  }
}

@media screen and (min-width: 1000px) {
  .events-wrapper .grid-row {
    justify-content: flex-start;
  }
  .events-wrapper .grid-row .grid-item {
    flex-basis: calc(33.3% - 20px);
    margin: 0px 10px 10px 10px;
  }
}

@media screen and (min-width: 1200px) {
  .events-wrapper .grid-row .grid-item {
    flex-basis: calc(25% - 20px);
  }
}

.event-card-image-wrapper {
  position: relative;
  height: 0;
  padding-top: calc(540 / 960 * 100%) !important;
}
.event-card-image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
}

.ui.tabular.menu .active.item {
  color: #6a69e2 !important;
  border-color: #6a69e2 !important;
}

/* Event Page */

.container {
  position: relative;
}
.event-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.content-event {
  display: flex;
  height: 100%;
  min-height: 100vh;
}

.sidebar {
  background-color: #2a2a3b;
  overflow: auto;
  min-height: 100%;
}

.sidebar-icon {
  position: absolute;
  top: 50%;
}

.sidebar-icon svg {
  background-color: #2a2a3b;
  cursor: pointer;
  border-radius: 50px;
  height: 50px;
  width: 50px !important;
  font-size: 12px;
  padding: 10px;
}

/* .sidebar-icon svg:hover {
  border: 2px solid #fff;
} */

.sidebar-expand {
  flex: 0 0 400px;
}

.sidebar-expand .sidebar-icon {
  left: calc(400px - 30px);
}

.sidebar-closed {
  flex: 0 0 20px;
}

.sidebar-closed .sidebar-icon {
  left: -10px;
}

.sidebar-closed div.sidebar-content {
  background-color: #2a2a3b;

  display: none;
}
.container-event {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  font-size: 50px;
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > img {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 5px;
  width: 100%;
}

.image-wrapper {
  flex-wrap: wrap;
}

.image-wrapper span {
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.image-wrapper img {
  height: 300px;
  width: auto;
}

@media all and (min-width: 600px) and (max-width: 990px) {
  .image-wrapper span {
    width: calc(100% / 2 - 10px);
    margin-right: 10px;
    height: 300px;
  }

  .image-wrapper span:nth-child(2n) {
    margin: 0;
  }
}
@media all and (min-width: 991px) and (max-width: 1199px) {
  .image-wrapper span {
    width: calc(100% / 6 - 10px);
    margin-right: 10px;
    height: calc(1200px / 6);
  }

  .image-wrapper span:nth-child(6n) {
    margin: 0;
  }
}
@media all and (min-width: 1199px) {
  .image-wrapper span {
    width: calc(100% / 8 - 10px);
    margin-right: 10px;
    height: calc(1200px / 8);
  }

  .image-wrapper span:nth-child(8n) {
    margin: 0;
  }
}

/** Event Page - FileUpload**/

.ImgCard {
  position: relative;
  width: 50%;
  height: 90%;
  flex-direction: column;
  display: flex;
  margin: 48px auto;
  padding: 0px;
  z-index: 3;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* File List */

.files-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.files-list-item {
  height: 60px;
  padding: 10px 0px 10px 10px;
  /*border-bottom: 1px solid #D3D3D3;*/
}
.files-list-item-content {
  float: left;
  padding-top: 5px;
  padding-left: 10px;
  width: calc(100% - 130px);
}
.files-list-item-content-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.files-list-item-content-item-1 {
  font-size: 20px;
  line-height: 30px;
}
.files-list-item-content-item-2 {
  font-size: 16px;
  line-height: 20px;
}
.files-list-item-preview {
  height: 60px;
  width: 60px;
  float: left;
}
.files-list-item-preview-image {
  height: 100%;
  width: 100%;
}
.files-list-item-preview-extension {
  text-align: center;
  line-height: 60px;
  color: #fff;
  background-color: #d3d3d3;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}
.files-list-item-remove {
  height: 60px;
  width: 60px;
  float: right;
  cursor: pointer;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTM4IDEyLjgzbC0yLjgzLTIuODMtMTEuMTcgMTEuMTctMTEuMTctMTEuMTctMi44MyAyLjgzIDExLjE3IDExLjE3LTExLjE3IDExLjE3IDIuODMgMi44MyAxMS4xNy0xMS4xNyAxMS4xNyAxMS4xNyAyLjgzLTIuODMtMTEuMTctMTEuMTd6Ii8+PHBhdGggZD0iTTAgMGg0OHY0OGgtNDh6IiBmaWxsPSJub25lIi8+PC9zdmc+)
    no-repeat center center;
  background-size: 30px 30px;
}
.files-list-item-remove-image {
  height: 30px;
  width: 30px;
  margin-top: 15px;
  margin-right: 10px;
  float: right;
}

.dragActive {
  padding: 10% 0 !important;
}

.opacityOnDragging {
  opacity: 0.2;
}

/* Profil Page */

.container-profil {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.container-profil .tab-wrapper {
  flex: 1;
  max-width: 90%;
}
.tab-wrapper .ui.attached.segment {
  border: none;
}
.tab-wrapper .ui.secondary.pointing.menu {
  border-bottom: none;
}
.profil-tab {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.container-profil .user-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.container-profil .user-image .profil-image-wrapper {
  position: relative;
}

.container-profil .user-image .profil-image-wrapper .profil-image {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 75px;
}

.container-profil .user-image span {
  margin-top: 15px;
  cursor: pointer;
  font-weight: medium;
  text-decoration: underline;
}

.container-profil .user-data {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container-profil .user-data .input-label {
  display: flex;
  flex-direction: column;
}

.container-profil .user-data .input-label label {
  font-weight: 500;
  margin-bottom: 5px;
}

.profil-tab .user-data .input-label,
.profil-tab .user-data > button,
.editprofil-tab .card .input-wrapper > input,
.editprofil-tab .card form > button {
  margin: 20px 0px 0px 0px;
}

.editprofil-tab {
  display: flex;
  flex-direction: column;
}

.editprofil-tab .card {
  margin-top: 50px;
}

.editprofil-tab .card form {
  display: flex;
  flex-direction: column;
}

.editprofil-tab .subline {
  margin: 0;
  font-weight: bold;
}

.editprofil-tab .input-wrapper {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .container-profil .tab-wrapper {
    flex: 0;
    min-width: 75%;
  }
  .container-profil .user-image {
    margin-bottom: 0;
    margin-right: 50px;
  }

  .profil-tab {
    flex-direction: row;
    text-align: left;
  }

  .editprofil-tab {
    max-width: 400px;
  }

  .profil-tab .user-data {
    max-width: 400px;
  }
}

.textInput {
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 10px 12px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz--moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
}

/** Error Page **/

#error-page {
  background-color: #a08fef;
  padding: 24px 24px 0px 24px;
  height: 100vh;
  width: 100vw;
}

#error-page .error-container .error-content h1 {
  font-size: 3rem !important;
  margin: 10px 0px 5px 0px;
}

#error-page .error-container .error-content h2 {
  margin: 0 0px 25px 0px;
}

#error-page .error-container {
  background-image: url("./assets/images/Eror404.jpg");
  background-size: auto 50%;
  background-repeat: no-repeat;
  background-position: bottom right;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

#error-page .error-container .error-content {
  margin-top: 10vh;
}

#error-page .button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#error-page .error-button {
  margin-left: 0;
}

@media only screen and (min-width: 576px) {
}

@media only screen and (min-width: 768px) {
  #error-page .error-container .error-content {
    max-width: 350px;
    margin-top: 25vh;
  }
}

@media only screen and (min-width: 992px) {
  #error-page .error-container {
    background-size: contain;
    justify-content: flex-start;
    align-items: center;
  }
  #error-page .error-container .error-content {
    margin-top: 0;
    margin-left: 15%;
  }
}
