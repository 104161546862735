#password-modal p {
  white-space: pre-line;
}
.modal-input {
  width: 100%;
  margin-bottom: 10px;
}
.modal-actions {
  display: flex;
}
.modal-button {
  margin-right: 10px;
}

.hasError {
  color: red;
  font-size: 0.9e;
}
